// When the user scrolls down 50px from the top of the document, resize the header's font size
window.onscroll = function() {scrollFunction()};

function scrollFunction() {
    if (window.screen.width >= 768) {
        if (document.body.scrollTop > 50 || document.documentElement.scrollTop > 50) {
            document.getElementById("header").classList.add("scrolling");
            document.getElementById("fade-in").src="/wp-content/uploads/2019/05/IDS-LOGO-2.png";
        } else {
            document.getElementById("fade-in").src="/wp-content/uploads/2019/04/IDS.png";
            document.getElementById("header").classList.remove("scrolling");
        }
    }
}

jQuery(document).ready(function() {
    var imageHeight, wrapperHeight, overlap, container = jQuery('.image-wrap');

    function centerImage() {
        imageHeight = container.find('img').height();
        wrapperHeight = container.height();
        overlap = (wrapperHeight - imageHeight) / 2;
        container.find('img').css('margin-top', overlap);
    }

    jQuery(window).on("load resize", centerImage);

    var el = document.getElementById('wrapper');
    if (el.addEventListener) {
        el.addEventListener("webkitTransitionEnd", centerImage, false); // Webkit event
        el.addEventListener("transitionend", centerImage, false); // FF eventisplay: none;
        el.addEventListener("oTransitionEnd", centerImage, false); // Opera event
    }

    jQuery("div#landinglogo").fadeIn('slow');

    jQuery('#postblock a').live("touchstart",function(e){
        var $link_id = jQuery(this).attr('id');
        if (jQuery(this).parent().data('clicked') == $link_id) {
            // element has been tapped (hovered), reset 'clicked' data flag on parent element and return true (activating the link)
            jQuery(this).parent().data('clicked', null);
            return true;
        } else {
            jQuery(this).trigger("mouseenter").siblings().trigger("mouseout"); //triggers the hover state on the tapped link (because preventDefault(); breaks this) and untriggers the hover state for all other links in the container.
            // element has not been tapped (hovered) yet, set 'clicked' data flag on parent element to id of clicked link, and prevent click
            e.preventDefault(); // return false; on the end of this else statement would do the same
            jQuery(this).parent().data('clicked', $link_id); //set this link's ID as the last tapped link ('clicked')
        }
    });
});

jQuery('.backtotop').click(function(){
    jQuery('html, body').animate({scrollTop:0}, 'slow');
});

function MM_swapImgRestore() { //v3.0
    var i,x,a=document.MM_sr; for(i=0;a&&i<a.length&&(x=a[i])&&x.oSrc;i++) x.src=x.oSrc;
}

function MM_preloadImages() { //v3.0
    var d=document; if(d.images){ if(!d.MM_p) d.MM_p=new Array();
        var i,j=d.MM_p.length,a=MM_preloadImages.arguments; for(i=0; i<a.length; i++)
            if (a[i].indexOf("#")!=0){ d.MM_p[j]=new Image; d.MM_p[j++].src=a[i];}}
}

function MM_findObj(n, d) { //v4.01
    var p,i,x;  if(!d) d=document; if((p=n.indexOf("?"))>0&&parent.frames.length) {
        d=parent.frames[n.substring(p+1)].document; n=n.substring(0,p);}
    if(!(x=d[n])&&d.all) x=d.all[n]; for (i=0;!x&&i<d.forms.length;i++) x=d.forms[i][n];
    for(i=0;!x&&d.layers&&i<d.layers.length;i++) x=MM_findObj(n,d.layers[i].document);
    if(!x && d.getElementById) x=d.getElementById(n); return x;
}

function MM_swapImage() { //v3.0
    var i,j=0,x,a=MM_swapImage.arguments; document.MM_sr=new Array; for(i=0;i<(a.length-2);i+=3)
        if ((x=MM_findObj(a[i]))!=null){document.MM_sr[j++]=x; if(!x.oSrc) x.oSrc=x.src; x.src=a[i+2];}
}